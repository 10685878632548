<template>
  <div class="animated fadeIn">
    <div id="Dashboard">
      <div style="height: 48px;">
        <b-button
          size="sm"
          variant="primary"
          class="mr-2"
          @click="
            $router.push({ name: 'Добавление прошивки', params: { id: '0' } })
          "
        >
          <i class="icon-plus"></i> Добавить
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          @click="
            () => {
              loadData(false);
            }
          "
        >
          <i class="icon-reload"></i> Обновить
        </b-button>
      </div>

      <Grid
        ref="firmwaresGrid"
        :items="gridData.firmwares"
        :columns="gridData.columns"
        :classes="gridData.classes"
        :actions="gridData.actions"
        :index="gridData.index"
        :filter-key="gridData.searchQuery"
        :defaultSort="gridData.sortBy"
        :defaultSortOrder="-1"
        :isDataLoading="isPageLoading"
      ></Grid>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import API from "../../../api/stalk.net";
import Grid from "../../../components/common/Grid";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  name: "Firmwares",
  components: {
    Grid
  },
  data() {
    return {
      isPageLoading: false,
      gridData: {
        firmwares: [],
        columns: [
          { title: "Id", path: "Id" },
          {
            title: "Тип",
            path: "FirmwareType",
            handler: str => {
              return API.getFirmwareTypeTitle(str);
            }
          },
          {
            title: "Тег",
            path: "FirmwareTag.Title",
            handler: str => {
              return typeof str !== "undefined" ? str : "";
            }
          },
          { title: "Версия", path: "Version" },
          {
            title: "Текущая?",
            path: "IsCurrent",
            handler: str => {
              return API.getCommonYesNo(str);
            }
          },
          { title: "Тег", path: "FirmwareTag.Title" },
          {
            title: "Файл",
            path: "Filename",
            handler: str => {
              return /[^/]*$/.exec(str)[0];
            }
          },
          {
            title: "Дата создания",
            path: "CreatedAt",
            handler: str => {
              return Vue.filter("formatDate")(str);
            }
          }
        ],
        index: { title: { path: "FirmwareType" }, id: { path: "Id" } },
        classes: [{ path: "IsCurrent", equals: true, type: "table-green" }],
        actions: [],
        searchQuery: "",
        sortBy: {}
      },
      baseURL: API.baseURL
    };
  },
  methods: {
    loadData(sort = true) {
      let self = this;

      self.isPageLoading = true;
      API.private
        .getFirmwares()
        .then(resp => {
          self.gridData.firmwares = resp.data;
          if (sort) {
            self.gridData.sortby = { title: "Id", path: "Id" };
            self.$refs.firmwaresGrid.sortBy(self.gridData.sortby);
          }
          self.isPageLoading = false;
        })
        .catch(this.catchError);
    },
    catchError(e) {
      let self = this;

      console.error(e);
      if (e.response && e.response.data && e.response.data.error) {
        console.error(e.response.data);
        switch (e.response.data.error) {
          case "ERR_BAD_JWT":
          case "ERR_BAD_AUTH":
          case "ERR_NOT_ALLOWED":
            {
              self.$router.replace("/login");
            }
            break;
        }
        self.showError({ message: e.response });
      }
      self.isPageLoading = false;
    }
  },
  beforeMount: function() {
    this.loadData();
  },
  notifications: {
    showError: {
      title: "Ошибка",
      type: "error"
    }
  }
};
</script>
